<template>
  <div class="in-workbench">
    <div class="left">
      <el-button type="primary" @click="dialog" style="text-align: left">新建责任成本(扩建)</el-button>
    </div>
    <div class="blank20"></div>
    <el-row :gutter=20>
      <el-col :span=24>
        <template>
          <el-table
            :header-cell-style="{background:'#e1e2e5',height: '90px'}"
            :row-style="{height:'70px',background:'#f8f7f7',color:'#606266'}"
            :data="tableData"
            style="width: 96%;margin-left: 2%;">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table
                  :border="true"
                  :data="props.row.costList"
                  style="width: 96%;margin-left: 2%;">
                  <el-table-column
                    label="物料名称"
                    width="300">
                    <template slot-scope="scope">
                      <i class="el-icon-time"></i>
                      <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="物料数量"
                    width="400" prop="num">
                  </el-table-column>
                  <el-table-column
                    label="责任成本类型"
                    width="400" prop="type">
                  </el-table-column>
                  <el-table-column
                    label="物料价格"
                    width="400" prop="price">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-link @click="deletemanagement(scope.row)">删除</el-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              label="管理的机组"
              prop="machineName">
              <!--              这里的name用机组名替代-->
            </el-table-column>
          </el-table>
        </template>
      </el-col>
    </el-row>

    <!--新增物料信息-->
    <template>
      <el-dialog title="责任成本(扩建)" :visible.sync="managementinfo" :append-to-body="true" width="50%"
                 top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
        <el-form>
          <el-row>
            <el-col :xs=24 :sm=12>
              <el-form-item label="选择机组" label-width="80px">
                <template>
                  <el-select v-model="addmanagementinfo.machineName" filterable placeholder="请选择">
                    <el-option
                      v-for="item in option"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>

            </el-col>
            <el-col :xs=24 :sm=12>
              <el-form-item label="选择物料" label-width="80px">
                <template>
                  <el-select v-model="addmanagementinfo.name" filterable placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </template>
              </el-form-item>
            </el-col>
            <el-col :xs=24 :sm=12>
              <el-form-item label="物料数量" label-width="80px">
                <el-input v-model="addmanagementinfo.num"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureadd">确定新增</el-button>
        <el-button type="danger" @click="back">取消新增</el-button>
      </span>
      </el-dialog>
    </template>
  </div>

</template>

<script>
import clientUtils from "../../js/clientUtils";

export default {
  name: "primecost",
  data: function () {
    return {
      tableData: [],
      managementinfo: false,
      fillpage: 1,
      addmanagementinfo: {},
      isnew: false,
      flag: false,
      pages: 0,
      option: [],
      options: [],
      pagetotal: null,
    }
  },
  methods: {
    dialog: function () {
      this.managementinfo = true
      console.log(this.managementinfo)
      this.addmanagementinfo= {
        goodsId:"",
        machineId:"",
        machineName:"",
        name:"",
        num:"",
        type:""
      }
    },
    sureadd: function () {
      const that = this
        this.addmanagementinfo.type = "扩建"
      if (that.addmanagementinfo.machineName == "" || that.addmanagementinfo.name == "" || that.addmanagementinfo.num == "") {
        clientUtils.popErrorMessage("请仔细填写信息")
      } else {
        for (let i = 0; i < that.options.length; i++) {
          for (let key in that.options[i]) {
            if (key == 'name' && that.options[i][key] == that.addmanagementinfo.name) {
              that.addmanagementinfo.goodsId = that.options[i].id
            }
          }
        }
        for (let i = 0; i < that.option.length; i++) {
          for (let key in that.option[i]) {
            if (key == 'name' && that.option[i][key] == that.addmanagementinfo.machineName) {
              that.addmanagementinfo.machineId = that.option[i].id
            }
          }
        }
        console.log(that.addmanagementinfo)
        clientUtils.post('/system/cost/save', that.addmanagementinfo, function (res) {
          if (res.data != null) {
            clientUtils.popSuccessMessage("新增成功")
            that.Variety()
            that.back()
          }
        })
      }
    },
    back: function () {
      this.managementinfo = false
      this.flag = false
    },
    deletemanagement: function (info) {
      console.log(JSON.stringify(info));
      const that = this;
      this.$confirm("确定要删除" + info.name + "这条物料数据吗?", clientUtils.Tip, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        clientUtils.post('/system/cost/removeByIds?ids=' + info.id, null, function (res) {
          if (res.data != null) {
            clientUtils.popSuccessMessage("删除成功");
            that.Variety()
          }
        })
      })
    },
    Variety: function () {
      const that = this
      clientUtils.get('/system/cost/findCost?type=扩建', function (res) {
        if (res.data != null) {
          that.tableData = res.data.data.data
          console.log(that.tableData)
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('selectMenu', this.$route.path, '责任成本');
      // 加载全部权限信息
    });
    const that = this
    clientUtils.get('/system/cost/findCost?type=扩建', function (res) {
      if (res.data != null) {
        that.tableData = res.data.data.data
        console.log(that.tableData)
      }
    })
const th = this
    clientUtils.get('/system/machine/selectMachineByUser',function (res) {
      if (res.data!=null){
        th.option = res.data
      }
    })
    clientUtils.get('/system/goods/findAll',function (res) {
      if (res!=null){
        that.options=res.data.data
      }
    })
  }

}
</script>

<style scoped>
.left {
  text-align: left;
}
</style>
